﻿@import './_variables.scss';
@import './_styles.scss';

body {
    font-family: $fontfamily;

    .smaller {
        transform: scale(0.8);
        top: -25px;
    }

    .spinner-text {
        color: #000000;
    }
}
